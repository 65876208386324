import React from "react";
import "./Footer.css";
import whiteLogo from "../assests/Xhipment_White_Lockup.svg";
import isoCard from "../assests/iso_seal.svg";

function Footer() {
  return (
    <>
    <div className="footerContainer">
     <footer className="mt-4 mb-3">
        <div className="container">
          <div className="row g-4 gx-2">
            <div className="col-md-4 xhipmentInfo">
              <img src={whiteLogo} alt="Xhipment White Logo" className="xhip_white_logo"/>
              <address>
                <p className="mt-1 xhip_info">
                  91 Springboard, Gopala Krishna Complex 45/3, <br />Residency Road,
                    MG Road, Bengaluru, <br />Karnataka - 560025
                </p>
                <p className="xhip_info d-flex flex-column">
                  <a href="mailto:support@xhipment.com" className="m-0 p-0">
                    support@xhipment.com
                  </a>
                  <a href="tel:+918069516681" className="m-0 p-0">+91 80695 16681</a>
                  </p>
                </address>
            </div>
            <div className="col-md-2">
              <h5>Quick Links</h5>
              <ul className="footerList">
                <li>
                  <a href="#aboutus">About Us</a>
                </li>
                <li>
                  <a href="#services">Services</a>
                </li>
                <li>
                  <a href="#faq">FAQs</a>
                </li>
                <li>
                  <a href="#tracking">Track Your Xhipment</a>
                </li>
                <li>
                    <a href='https://s3.us-east-1.amazonaws.com/www.xhipment.com/Xhip-Track.apk' >Download Xhip-Track App</a>
                </li>
                <li>
                  <a href="/Home/Terms">Terms of Use</a>
                </li>
                <li>
                  <a href="/Home/Privacy">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2">
              <h5>Social</h5>
              <ul className="footerList">
                <li>
                  
                  <a href="https://www.facebook.com/Xhipment" target="_blank" rel="noreferrer">Facebook</a>
                </li>
                <li>
                  <a href="https://www.instagram.com/xhipment/" target="_blank" rel="noreferrer">Instagram</a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/xhipment/" target="_blank" rel="noreferrer">LinkedIn</a>
                </li>
                <li>
                  <a href="https://twitter.com/Xhipment" target="_blank" rel="noreferrer">Twitter</a>
                </li>
                <li>
                  <a href="https://labs.xhipment.com/" target="_blank" rel="noreferrer">Xhipment Labs</a>
                </li>
              </ul>
            </div>
            <div className="col-auto ms-md-auto order-start iso_parent">
              <img src={isoCard} alt="ISO Card" className="isoCard" />
            </div>
          </div>
        </div>
      </footer>
    </div>
    <div className="footer_container2">
      Copyright &#169; Holaport Logistics Private Limited. All rights reserved{" "}
    </div>
    </>
  );
}

export default Footer;