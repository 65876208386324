import React from "react";
import "./index.css"
import FFFAI from "../../assests/FFFAI.png"
import MTO from "../../assests/MTO.png"
import FMC from "../../assests/FMC.png"
import JCTrans from "../../assests/JCTrans.png"

function License() {
  return (
    <>
      <section className="License-conatainer" id='license'>
        <h2>Our Licenses & Memberships</h2>

        <div className="container license_cards">
          <a href="" target={"blank"}>
            <div className="license_card">
              <img src={MTO} alt="logo" />
              <p>Multimodal Transport Operator</p>
            </div>
          </a>
          <a href="https://www.fmc.gov/" target={"blank"}>
            <div className="license_card">
              <img src={FMC} alt="logo" />
              <p>U.S. Federal Maritime Commission</p>
            </div>
          </a>
          <a href="https://www.fffai.org/" target={"blank"}>
            <div className="license_card">
              <img src={FFFAI} alt="logo" />
              <p>Federation of Freight Forwarders' Associations in India</p>
            </div>
          </a>
          <a href="https://www.jctrans.com/" target={"blank"}>
            <div className="license_card">
              <img src={JCTrans} alt="logo" />
              <p>Premium Member - JC Trans Global Logistics Network</p>
            </div>
          </a>
          <a href="https://www.iata.org/" target={"blank"}>
            <div className="license_card">
              <img src="https://upload.wikimedia.org/wikipedia/commons/0/08/Iata_logo_225.svg" alt="logo" />
              <p>International Air Transport Association (IATA)</p>
            </div>
          </a>
        </div>
      </section>
    </>
  )
}

export default License;
