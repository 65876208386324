import React, { useState } from 'react'
import './index.css';
import { motion } from 'framer-motion'
import moment from 'moment'
import apk from '../../assests/Xhip-Track.apk'

const Tracking = () => {

  const [trackingNumber, setTrackingNumber] = useState('')
  const [trackingData, setTrackingData] = useState([])
  const [trackingDataLoading, setTrackingDataLoading] = useState(false)
  const [trackingDataError, setTrackingDataError] = useState(false)

  const getTrackingData = () => {
    setTrackingDataLoading(true)
    setTrackingDataError(false)
    setTrackingData([])

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/v1/utils/publictracking?track=${trackingNumber}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 200) {
          setTrackingData(result.result.milestones)
        } else if (result.status === 500) {
          setTrackingDataError("Please check your Quote ID and try again")
        }
        else {
          setTrackingDataError(result?.message)
        }
        setTrackingDataLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setTrackingDataError(error?.message)
        setTrackingDataLoading(false)
      });
  }

  function MangeMileStones(milestones, status) {
    let mileStones = [];
    let index = 0;

    const exclude = [
      'Vessel arrived at port',
      'Vessel departed from port',
      'Container discharged from vessel',
      'Container discharged at destination',
      'Container loaded at transshipment port',
      'Shipment out for delivery',
      'Empty Container Gate In'
    ];

    const filteredMilestones = milestones.filter((mile) => !exclude.includes(mile.value));

    milestones = filteredMilestones;

    if (milestones[0].dateTime) {
      milestones.forEach((milestone, i) => {
        if (status === 'Booking On Hold' || status === 'Booking Cancelled') {
          if (milestone.value === 'Booking Confirmed' && milestone.dateTime) {
            mileStones.push(milestone);
          }
          if (milestone.value === 'Booking On Hold' && milestone.dateTime) {
            mileStones.push(milestone);
          }
          if (milestone.value === 'Booking Cancelled' && milestone.dateTime) {
            mileStones.push(milestone);
          }

        } else {
          if (milestone.value && milestone.dateTime && milestone.value !== 'Booking On Hold' && milestone.value !== 'Booking Cancelled') {

            mileStones.push(milestone);
            index = i + 1;
          }

        }
      });
    } else {
      mileStones = [...milestones]
    }

    if (index !== 0) {
      for (let i = index; i < milestones.length; i++) {
        if (milestones[i].value !== 'Booking On Hold' && milestones[i].value !== 'Booking Cancelled') mileStones.push(milestones[i])
      }
    }

    return mileStones;
  }

  const submitIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M5.04218 6.93062L17.08 3.05402C18.2218 2.68633 19.4733 2.98853 20.3215 3.83671C21.2035 4.7187 21.4922 6.03303 21.061 7.20345L16.7032 19.0317C15.769 21.5674 12.2982 21.8564 10.9575 19.5102L9.189 16.4154C8.85975 15.8392 8.38796 15.3572 7.8189 15.0158L4.35197 12.9356C1.90687 11.4686 2.328 7.80467 5.04218 6.93062Z" fill="white" />
    <path d="M9.05134 16.1923C8.78188 15.7871 8.43844 15.4363 8.03906 15.1582C8.07082 15.104 8.10997 15.0528 8.15652 15.0063L10.9849 12.1778C11.2778 11.8849 11.7527 11.8849 12.0456 12.1778C12.3385 12.4707 12.3385 12.9456 12.0456 13.2385L9.21718 16.0669C9.16668 16.1174 9.11078 16.1592 9.05134 16.1923Z" fill="white" />
  </svg>

  const checkIcon = <svg style={{ marginTop: "-0.1rem" }} width="18" height="16" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5281 1.21528L7.41928 11.3233C7.35131 11.3916 7.27054 11.4457 7.1816 11.4827C7.09266 11.5196 6.9973 11.5386 6.901 11.5386C6.80469 11.5386 6.70933 11.5196 6.62039 11.4827C6.53145 11.4457 6.45069 11.3916 6.38272 11.3233L2.47351 7.41047C2.40555 7.34223 2.32478 7.28809 2.23584 7.25115C2.1469 7.21421 2.05154 7.19519 1.95523 7.19519C1.85893 7.19519 1.76357 7.21421 1.67463 7.25115C1.58569 7.28809 1.50492 7.34223 1.43696 7.41047C1.36872 7.47843 1.31458 7.5592 1.27764 7.64814C1.2407 7.73708 1.22168 7.83244 1.22168 7.92874C1.22168 8.02505 1.2407 8.12041 1.27764 8.20935C1.31458 8.29829 1.36872 8.37906 1.43696 8.44702L5.34763 12.357C5.76016 12.7687 6.31922 13 6.9021 13C7.48497 13 8.04403 12.7687 8.45656 12.357L18.5646 2.2511C18.6327 2.18315 18.6868 2.10243 18.7237 2.01355C18.7605 1.92468 18.7795 1.82941 18.7795 1.73319C18.7795 1.63697 18.7605 1.54169 18.7237 1.45282C18.6868 1.36395 18.6327 1.28323 18.5646 1.21528C18.4967 1.14704 18.4159 1.0929 18.3269 1.05596C18.238 1.01902 18.1427 1 18.0463 1C17.95 1 17.8547 1.01902 17.7657 1.05596C17.6768 1.0929 17.596 1.14704 17.5281 1.21528Z" fill="#091649" stroke="#091649" />
  </svg>

  const warningIcon = <svg width="20" height="20" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M18.0868 22.25H6.91329C2.97422 22.25 0.444016 18.0662 2.27336 14.5777L7.37463 4.84967C8.37399 2.94392 10.3482 1.75 12.5001 1.75C14.6519 1.75 16.6262 2.94392 17.6255 4.84967L22.7268 14.5777C24.5561 18.0662 22.0259 22.25 18.0868 22.25Z" fill="#FF0000" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 7.5C12.9142 7.5 13.25 7.83579 13.25 8.25L13.25 11.25C13.25 11.6642 12.9142 12 12.5 12C12.0858 12 11.75 11.6642 11.75 11.25L11.75 8.25C11.75 7.83579 12.0858 7.5 12.5 7.5Z" fill="#FF0000" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 16.75C11.9477 16.75 11.5 16.3023 11.5 15.75L11.5 15.749C11.5 15.1967 11.9477 14.749 12.5 14.749C13.0523 14.749 13.5 15.1967 13.5 15.749L13.5 15.75C13.5 16.3023 13.0523 16.75 12.5 16.75Z" fill="#FF0000" />
  </svg>


  return (
    <>
      <section className="xhipTrack-conatainer" id='tracking'>
        <div className='xhipTrack_heading'>
          <h2>Track Your Xhipment</h2>
          <span className="section-title-line"></span>
        </div>
        <div className='xhipTrackSection_container'>
          <motion.section className='Tracksections'
            initial={{ opacity: 0.6, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 1.0 }}>
            <div className='inputContainer' >
              <input type='text' placeholder='Please enter your Quote ID e.g. XQFCL12345678'
                onChange={(e) => setTrackingNumber(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') getTrackingData();
                }}
              />
              <button
                onClick={() => {
                  getTrackingData();
                }
                }
                disabled={trackingDataLoading}
              >
                {trackingDataLoading ?
                  <div className="spinner-border spinner-border-sm text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  :
                  submitIcon}
                Track Xhipment
              </button>
            </div>
          </motion.section>

          {/* Milestones Data */}
          {trackingData?.length > 0 &&
            <div className='milestonesContainer trackMilestone'>
              <label>Tracking Status</label>
              <div className='milestones'>
                {MangeMileStones(trackingData, null)?.map((item, index) => (
                  <div key={index + 'milestone'}
                    className={
                      ((item.value === 'Booking On Hold' && !item.dateTime) || (item.value === 'Booking Cancelled' && !item.dateTime) || (item.value === 'Held at Origin Customs (exception)' && !item.dateTime) || (item.value === 'Held at Destination Customs (exception)' && !item?.dateTime))
                        ? 'd-none'
                        : item?.dateTime ? 'mile' : 'mile disabled'}>
                    {/* {index !== 0 && <div className="line"></div>} Add line except for the first milestone */}
                    <span className={item?.dateTime ? 'checkIcon' : "checkIcon inactive"}>{checkIcon}</span>
                    <div className='milestoneName'>
                      <p className='m-0 text-'>{item?.view?.customer ? item?.view?.customer : item.value}</p>
                      <span>{item?.dateTime ? moment(item?.dateTime).format('DD MMM YYYY') : ""}</span>
                    </div>
                  </div>))}
              </div>
            </div>
          }

          {/* Error and other Information */}
          {trackingDataError &&
            <div className='milestoneError'>
              {warningIcon}
              {trackingDataError || "Some error occured, Please try again after some time"}
            </div>
          }

        </div>
        {/* 
        <a className='xhipTrack_footer' download={apk} href='/'>
          Download Xhip-Track App
        </a> */}
      </section>
    </>
  );
}

export default Tracking;